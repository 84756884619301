import { Link, NavLink, useHistory } from 'react-router-dom';
import logo from '../../img/logo.png';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import classes from './MainNavigation.module.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

//import { FaWindows } from 'react-icons/fa';
import { HashLink as HLink } from 'react-router-hash-link';

function MainNavigation() {
	const [logintext, setText] = useState('Login');
	const [signintext, setSignText] = useState('login');
	const [usertext, setUserText] = useState('sign-up');
	const [newusertext, setNewUserText] = useState('Register');
	const walletid = window.sessionStorage.getItem('user_wallet_id');
	const [loading, setLoading] = useState(false);
	let history = useHistory();
	//let token = '';
	if (localStorage.getItem('auth-token')) {
		var token = localStorage.getItem('auth-token');
		sessionStorage.setItem('token', token);
	}

	var usertoken = token;
	useEffect(() => {
		if (!token) {
			if (!usertoken || usertoken === null) {
				history.push('/');
			}
		} else {
			if (logintext === 'Login') {
				setText('Log Out');
				setSignText('logout');
				setNewUserText('Dashboard');
				setUserText('dashboard');
			} else {
			}
			if (!walletid) {
				sessionStorage.clear();
				localStorage.clear();
				setText('Login');
				setSignText('login');
				setNewUserText('Register');
				setUserText('sign-up');
				history.push('/');
			}
		}
	}, [token, logintext, history, usertoken, walletid]);

	const handleClick = () => {
		if (logintext === 'Log Out') {
			setLoading(true);
			axios
				.post('https://savebox.cyrusnet4d.com/public/api/logout')
				.then((res) => {
					if (res.data.status === 200) {
						sessionStorage.clear();
						window.localStorage.removeItem('auth-token');
						// window.sessionStorage.removeItem('token');
						// localStorage.clear();
						Swal.fire({
							icon: 'success',
							title: 'Logged Out',
							text: 'Successful!',
						});
						setText('Login');
						setSignText('login');
						setNewUserText('Register');
						setUserText('sign-up');
						setLoading(false);
						history.push('/');
					}
				});
		}
	};

	if (loading) {
		return (
			<div className={classes.dashboard}>
				<p className={classes.loading}></p>
				<p className={classes.loadingText}>Signing out...</p>
			</div>
		);
	}

	return (
		<Navbar
			collapseOnSelect
			expand="lg"
			bg="white"
			variant="light"
			sticky="top"
		>
			<Container>
				<Navbar.Brand>
					<NavLink to="/">
						<img src={logo} width="" alt="" className={classes.logo} />
					</NavLink>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="me-auto">
						<HLink
							className={classes.nav}
							to="#wrapper"
							// activeStyle={{
							// 	color: '#000',
							// }}
						>
							Get Started
						</HLink>
						{/* <HLink
							className={classes.nav}
							to="#"
							activeStyle={{
								color: '#71962D',
							}}
						>
							Set Goals
						</HLink> */}
						<HLink
							className={classes.nav}
							to="#section-faqs"
							// activeStyle={{
							// 	color: '#71962D',
							// }}
						>
							FAQs
						</HLink>
						{/* <NavLink
							className={classes.nav}
							to="/api-doc"
							activeStyle={{
								color: '#71962D',
							}}
						>
							API
						</NavLink> */}
					</Nav>

					<Nav>
						{/* {!token ? (
							<Link className={classes.nav} to="/sign-up">
								<Button variant="default">New User</Button>
							</Link>
						) : null} */}

						{/* <Link className={classes.nav} to={usertext}>
							<Button variant="default">{newusertext}</Button>
						</Link> */}

						<Link className={classes.nav} to={signintext}>
							<Button className={classes.btnheader} onClick={handleClick}>
								{logintext}
							</Button>
						</Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
			<div className="mobile-nav__wrapper">
				<div className="mobile-nav__overlay mobile-nav__toggler" />
				<div className="mobile-nav__content">
					<span className="mobile-nav__close mobile-nav__toggler">
						<i className="fas fa-plus" />
					</span>
					<div className="logo-box">
						<a href="index.html" aria-label="logo image">
							<img src="assets/images/logo-1.png" alt="" />
						</a>
					</div>
					<div className="mobile-nav__container" />
					<ul className="mobile-nav__contact list-unstyled">
						<li>
							<i className="fa fa-envelope" />
							<a href="mailto:info@example.com">info@savebox.ng</a>
						</li>
						<li>
							<i className="fa fa-phone-alt" />
							<a href="tel:2348077756784">234-807-7722-784</a>
						</li>
					</ul>
					<div className="mobile-nav__social">
						<a href="#" className="fab fa-twitter" />
						<a href="#" className="fab fa-facebook-square" />
						<a href="#" className="fab fa-pinterest-p" />
						<a href="#" className="fab fa-instagram" />
					</div>
				</div>
			</div>
		</Navbar>
	);
}
export default MainNavigation;
