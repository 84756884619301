import { Container, Row, Col } from 'react-bootstrap';
import classes from '../components/layouts/GetStarted.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import banner from '../img/headerBanner.png';
// import checkList from '../img/checklist.png';
// import target from '../img/target.png';
// import StartSaving from '../components/layouts/StartSaving';
import { Link } from 'react-router-dom';

function GetStartedPage() {
	return (
		<div>
			<Container>
				<Row>
					<Col>
						<h2 className={classes.headerText}>Saving couldn’t be easier</h2>
						<img src={banner} width="" alt="" className={classes.banner} />
						<p className={classes.bannerText}>
							We have made it easy for you to save for your dreams and goals.
						</p>
						<Link className={classes.btnStart} to="/sign-up">
							START TODAY!
						</Link>
					</Col>
				</Row>
				<Row className={classes.bodyContainer}>
					{/* <Col className={classes.savingsCard}>
						<img src={checkList} alt="" className={classes.cardImg} />
						<h2 className={classes.cardHeader}>Savings Plan</h2>
						<p>
							Save, transfer, withdraw, manage and organise your money at any
							time.
						</p>
					</Col>
					<Col className={classes.savingsCard}>
						<img src={target} alt="" className={classes.cardImg} />
						<h2 className={classes.cardHeader}>Savings Goal</h2>
						<p>
							Purpose Savings is designed to help you reach all your savings
							goals easily.
						</p>
					</Col> */}
				</Row>
				{/* <StartSaving /> */}
			</Container>
		</div>
	);
}

export default GetStartedPage;
