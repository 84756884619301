import React, { useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useHistory, Link } from 'react-router-dom';

const Login = () => {
	const history = useHistory();
	const emailRef = useRef();
	const passwordRef = useRef();
	const [loginInput, setLogin] = useState({
		email: '',
		password: '',
	});
	const handleInput = (e) => {
		e.persist();
		setLogin({ ...loginInput, [e.target.name]: e.target.vaue });
	};

	const loginSubmit = (e) => {
		e.preventDefault();

		const data = {
			email: emailRef.current.value,
			password: passwordRef.current.value,
		};

		axios.get('/sanctum/csrf-cookie').then((response) => {
			axios.post(`api/login`, data).then((res) => {
				if (res.data.status === 200) {
					window.localStorage.setItem('auth-token', res.data.token);
					window.localStorage.setItem('auth-email', res.data.user.email);
					//window.sessionStorage.setItem('token', res.data.token);
					Swal.fire({
						icon: 'success',
						title: 'Login',
						text: 'Successful!',
					});
					history.push('/dashboard');
				} else {
					Swal.fire({
						icon: 'error',
						title: 'Registration',
						text: 'Failed!',
					});
				}
			});
		});
	};

	return (
		<div>
			<div className="container">
				<div className="row">
					<div className="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
						<form className="form">
							<div className="card card-login card-hidden">
								<div className="card-header card-header-rose text-center">
									<h4 className="card-title">Login</h4>
									<div className="social-line">
										<Link
											href="#pablo"
											className="btn btn-just-icon btn-link btn-white"
										>
											<i className="fa fa-facebook-square" />
										</Link>
										<Link
											href="#pablo"
											className="btn btn-just-icon btn-link btn-white"
										>
											<i className="fa fa-twitter" />
										</Link>
										<Link
											href="#pablo"
											className="btn btn-just-icon btn-link btn-white"
										>
											<i className="fa fa-google-plus" />
										</Link>
									</div>
								</div>
								<div className="card-body ">
									<p className="card-description text-center">
										Or Be Classical
									</p>

									<span className="bmd-form-group">
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="material-icons">email</i>
												</span>
											</div>
											<input
												ref={emailRef}
												onChange={handleInput}
												type="email"
												className="form-control"
												placeholder="Email..."
											/>
										</div>
									</span>
									<span className="bmd-form-group">
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="material-icons">lock_outline</i>
												</span>
											</div>
											<input
												ref={passwordRef}
												onChange={handleInput}
												type="password"
												className="form-control"
												placeholder="Password..."
											/>
										</div>
									</span>
								</div>
								<div className="card-footer justify-content-center">
									<Link
										onClick={loginSubmit}
										className="btn btn-rose btn-link btn-lg"
									>
										Lets Go
									</Link>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
