import React, { useState } from 'react';
import TableFooter from '././TableFooter/TableFooter';
import useTable from '././useTable';
import styles from '../components/layouts/Table.module.css';

const Table = ({ data, rowsPerPage }) => {
	const [page, setPage] = useState(1);
	const { slice, range } = useTable(data, page, rowsPerPage);
	return (
		<div className="container">
			<div className="content-header">
				<div className="container-fluid">
					<div className="row mb-2">
						<div className="col-sm-6">
							<h3 className="m-0">Recent Transactions</h3>
						</div>
						<div className="col-sm-6">
							<ol className="breadcrumb float-sm-right">
								<li className="breadcrumb-item">
									<a href="/dashboard">Home</a>
								</li>
								<li className="breadcrumb-item active">Transactions</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			<section className="content">
				<div className="table-responsive">
					<table>
						<thead>
							<tr>
								<th className={styles.tableHeader} width="15%">
									Date/Time
								</th>
								<th className={styles.tableHeader}>Transaction ID</th>
								<th className={styles.tableHeader}>Transaction Type</th>
								<th className={styles.tableHeader}>Transaction Amount</th>
								<th className={styles.tableHeader} width="15%">
									Transaction Naration
								</th>
								<th className={styles.tableHeader}>Status</th>
							</tr>
						</thead>
						<tbody>
							{slice.map((el) => (
								<tr className={styles.tableRowItems} key={el.id}>
									<td className={styles.tableCell}>{el.trans_date}</td>
									<td className={styles.tableCell}>{el.trans_id}</td>
									<td className={styles.tableCell}>{el.trans_type}</td>
									<td className={styles.tableCell}>{el.amount}</td>
									<td className={styles.tableCell}>{el.trans_desc}</td>
									<td className={styles.tableCell}>{el.trans_status}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</section>
			<TableFooter range={range} slice={slice} setPage={setPage} page={page} />
		</div>
	);
};

export default Table;
