import { useHistory } from 'react-router-dom';
// import { Container, Button, Form, Row, Col } from 'react-bootstrap';
import classes from '../../components/layouts/Contribute.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useRef, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

function MakeTransferPage() {
	const [disable, setDisable] = React.useState(true);
	const walletRef = useRef();
	const transferAmountRef = useRef();
	let usertoken = sessionStorage.getItem('token');
	// let user_email = sessionStorage.getItem('user_email');
	let sender_wallet_id = window.sessionStorage.getItem('user_wallet_id');
	const [beneficiary, setBeneficiary] = useState('');
	// const [beneficiaryBalance, setBeneficiaryBalance] = useState();

	const history = useHistory();
	if (!usertoken) {
		history.push('/');
	}

	// if (walletRef.current.value === '') {
	// 	setDisable(true);
	// }

	function clear() {
		walletRef.current.value = transferAmountRef.current.value = '';
		setBeneficiary('');
	}

	const fetch_wallet_data = () => {
		const data = {
			wallet_id: walletRef.current.value,
		};
		if (
			walletRef.current.value.length > 7 &&
			walletRef.current.value !== sender_wallet_id
		) {
			try {
				axios.get('/sanctum/csrf-cookie').then((response) => {
					axios.post(`api/wallet_data`, data).then((res) => {
						if (res.data[0]) {
							var beneficiaryName = res.data[0].cust_name;
							setBeneficiary(beneficiaryName);
							// setBeneficiaryBalance(res.data[0].acct_balance);
							if (beneficiaryName) {
								Swal.fire({
									icon: 'success',
									title: 'Wallet found',
									text: beneficiary,
								});
								setDisable(false);
							}
						} else {
							// setBeneficiary('');
						}
					});
				});
			} catch (error) {}
		} else if (walletRef.current.value === sender_wallet_id) {
			Swal.fire({
				icon: 'error',
				title: 'Wallet',
				text: 'You cannot transfer funds to this wallet!',
			});
			// setBeneficiary('');
		} else {
			// setBeneficiary('');
		}
	};

	const transferSubmit = (e) => {
		e.preventDefault();
		setDisable(true);
		var transferAmount = transferAmountRef.current.value;
		var userBalance = sessionStorage.getItem('available_balance');
		transferAmount = Math.round(transferAmount);
		userBalance = Math.round(userBalance);

		if (userBalance >= transferAmount) {
			const data = {
				sender_id: sender_wallet_id,
				wallet_id: walletRef.current.value,
				// sender_balance: userBalance,
				// beneficiaryBalance: beneficiaryBalance,
				transferAmount: transferAmount,
			};

			axios.get('/sanctum/csrf-cookie').then((response) => {
				axios.post(`api/transfer`, data).then((res) => {
					console.warn(res.data);
					if (res.data.status === 200) {
						//console.log(res.data.new_sender_balance[0].acct_balance);
						sessionStorage.setItem(
							'available_balance',
							res.data.new_sender_balance[0].available_bal,
						);
						clear();
						setDisable(false);
						Swal.fire({
							icon: 'success',
							title: 'Transfer',
							text: 'Successful!',
						});
					} else {
						Swal.fire({
							icon: 'error',
							title: 'Payment',
							text: 'Failed!',
						});
						setDisable(false);
					}
				});
			});
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Transfer',
				text:
					'Failed! Insufficient Wallet Balance: ' +
					new Intl.NumberFormat('ja-JP', {
						style: 'currency',
						currency: 'NGN',
					}).format(userBalance),
			});
			setDisable(false);
		}
	};

	return (
		<div className={classes.transfer}>
			<div className="container">
				<div className="content-header">
					<div className="container-fluid">
						<div className="row mb-2">
							<div className="col-sm-6">
								<h3 className="m-0">Transfer</h3>
							</div>
							<div className="col-sm-6">
								<ol className="breadcrumb float-sm-right">
									<li className="breadcrumb-item">
										<a href="/dashboard">Home</a>
									</li>
									<li className="breadcrumb-item active">Transfer</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<section className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12 col-sm-6 col-md-10">
								<form
									className={classes.form}
									noValidate
									onSubmit={transferSubmit}
								>
									<div className="form-row">
										<div className="col-md-4 mb-3">
											<input
												type="text"
												className="form-control border"
												placeholder="WalletID"
												ref={walletRef}
												onChange={fetch_wallet_data}
												required
											/>
											<div className={classes.beneficiary}>{beneficiary}</div>
										</div>
									</div>
									<div className="form-row">
										<div className="col-md-4 mb-3">
											<input
												type="text"
												className="form-control border"
												placeholder="Amount"
												ref={transferAmountRef}
												required
											/>
											<div className="invalid-feedback">
												Please enter a valid amount
											</div>
										</div>
									</div>

									<button
										className={classes.transferbtn}
										type="submit"
										disabled={disable}
									>
										Transfer
									</button>
								</form>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}

export default MakeTransferPage;
