// import { Container, Row, Col } from 'react-bootstrap';
// import classes from '../components/layouts/GetStarted.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import banner from '../img/headerBanner.png';
import { useState } from 'react';

import { Link } from 'react-router-dom';

function HomePage() {

	const [activeIndex, setActiveIndex] = useState(0);

	const faqItems = [
	  {
		question: 'What is the minimum balance?',
		answer: `The minimum balance requirement varies based on the type of account you have with Savebox.
				 Our basic savings account requires a minimum balance of NGN1000. However, for specialized accounts like
				 investment portfolios, the minimum balance might differ. You can find specific details in your account settings.`,
	  },
	  {
		question: 'What is the rate of interest?',
		answer: `The rate of interest depends on the type of account or investment you choose. Our savings
				 accounts offer a competitive annual interest rate of 1.5%. Investment portfolios, on the other hand, have
				 variable rates based on market conditions. You can find the most up-to-date interest rates within your account dashboard.`,
	  },
	  {
		question: 'How do I set up a savings goal?',
		answer: `Setting up a savings goal is simple with Savebox. After logging into your account, navigate to the "Goals"
				 section and click "Create New Goal." Specify the goal type, amount, and target date. Our app will automatically
				 calculate a monthly contribution based on your preferences. You can customize your goal details and track your progress at any time.`,
	  },
	  {
		question: 'Can I change my investment preferences later on?',
		answer: `Absolutely! We understand that financial goals and preferences evolve. With Savebox, you have the flexibility
				 to adjust your investment preferences whenever you'd like. Simply log in, go to the "Investments" tab, and explore different
				 investment options. You can reallocate funds or choose new investments that align with your current objectives.`,
	  },
	];
  
	const handleSelect = (index) => {
	  setActiveIndex(index);
	};


	return (
		<div>
			<div className="page-wrapper clearfix">
				{/* <header className="main-header main-header-style1">
					<nav className="main-menu main-menu-style1">
						<div className="main-menu__wrapper clearfix">
							<div className="container">
								<div className="main-menu__wrapper-inner">
									<div className="main-menu-style1-left">
										<div
											className="logo-box-style1"
											style="border-top-left-radius: 15px"
										>
											<a href="index.html">
												<img
													src="assets/images/logo-1.png"
													alt="SaveBox Logo"
													title=""
												/>
											</a>
										</div>

										<div className="main-menu-box">
											<a href="#" className="mobile-nav__toggler">
												<i className="icon-menu"></i>
											</a>

											<ul className="main-menu__list">
												<li>
													<a href="index.html">Home</a>
												</li>

												<li className="dropdown">
													<a href="#">Services</a>
													<ul>
														<li className="dropdown">
															<a href="#">Accounts</a>
															<ul>
																<li>
																	<a href="#">All Accounts</a>
																</li>
																<li>
																	<a href="#">Target Savings</a>
																</li>
																<li>
																	<a href="#">Flexible Savings</a>
																</li>
																<li>
																	<a href="#">Fixed Savings</a>
																</li>
															</ul>
														</li>

														<li className="dropdown">
															<a href="#">Loans</a>
															<ul>
																<li>
																	<a href="#">Home Loan</a>
																</li>
																<li>
																	<a href="#">Personal Loan</a>
																</li>
																<li>
																	<a href="#">Vehicle Loan</a>
																</li>
																<li>
																	<a href="#">Start-Up Loan</a>
																</li>
															</ul>
														</li>
													</ul>
												</li>

												<li className="dropdown">
													<a href="#">Apply Now</a>
													<ul>
														<li>
															<a href="#">Home Loan</a>
														</li>
														<li>
															<a href="#">Personal Loan</a>
														</li>
														<li>
															<a href="#">Vehicle Loan</a>
														</li>
														<li>
															<a href="#">Start-Up Loan</a>
														</li>
													</ul>
												</li>
												<li>
													<a href="#">Get In Touch</a>
												</li>
											</ul>
										</div>
									</div>

									<div className="main-menu-style1-right">
										<div className="header-btn-one">
											<a href="#">
												<span className=""></span>Login
											</a>
											<a
												className="style2"
												href="#"
												style="border-top-right-radius: 15px"
											>
												<span className=""></span>Sign Up
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</nav>

					<div className="main-header-style1-bottom">
						<div className="auto-container">
							<div className="outer-box">
								<div className="update-box">
									<div className="inner-title">
										<span className="icon-megaphone"></span>
										<h4>Update:</h4>
									</div>
									<div className="text">
										<p>Get upto 4%* on your Savings Account with us.</p>
										<a href="#">
											<span className="icon-chevron"></span>More Details
										</a>
									</div>
								</div>
								<div className="slogan-box">
									<p>
										Dear Customer, We have launched Video KYC facility for New
										customer to open savings ac
									</p>
								</div>
							</div>
						</div>
					</div>
				</header>

				<div className="stricky-header dark-home stricked-menu main-menu">
					<div className="sticky-header__content"></div>
				</div> */}

				<section className="main-slider main-slider-style1">
					<div
						className="swiper-container thm-swiper__slider"
						data-swiper-options='{"slidesPerView": 1, "loop": true,
			"effect": "fade",
			"pagination": {
			"el": "#main-slider-pagination",
			"type": "bullets",
			"clickable": true
			},
			"navigation": {
			"nextEl": "#main-slider__swiper-button-next",
			"prevEl": "#main-slider__swiper-button-prev"
			},
			"autoplay": {
			"delay": 5000
			}}'
					>
						<div className="swiper-wrapper">
							<div className="slider-buttom-box">
								<a className="style2" href="#">
									Make an Enquiry <span className="icon-play-button" />
								</a>
							</div>
							<div className="swiper-slide">
								<div
									className="image-layer"
									style={{
										backgroundImage: 'url(assets/images/slides/slide-v1-2.jpg)',
									}}
								/>
								<div className="container">
									<div className="row">
										<div className="col-xl-12">
											<div className="main-slider-content">
												<div className="main-slider-content__inner">
													<div className="big-title">
														<h2>
															Savings Made
															<br />
															Easy, More Secure &amp;
															<br />
															More Personal
														</h2>
													</div>
													<div className="text">
														<p />
													</div>
													<div className="btns-box">
														<a className="btn-one" href="/login">
															<span className="txt"> Get Started </span>
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="swiper-slide">
								<div
									className="image-layer"
									style={{
										backgroundImage: 'url(assets/images/slides/slide-v1-1.jpg)',
									}}
								/>
								<div className="container">
									<div className="row">
										<div className="col-xl-12">
											<div className="main-slider-content">
												<div className="main-slider-content__inner">
													<div className="big-title">
														<h2>
															Another <br />
															Opportunity to Start
															<br />
															Again
														</h2>
													</div>
													<div className="text">
														<p />
													</div>
													<div className="btns-box">
														<a className="btn-one" href="/login">
															<span className="txt"> Get Started </span>
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="main-slider__nav">
							<div
								className="swiper-button-prev"
								id="main-slider__swiper-button-next"
							>
								<i className="icon-chevron left" />
							</div>
							<div
								className="swiper-button-next"
								id="main-slider__swiper-button-prev"
							>
								<i className="icon-chevron right" />
							</div>
						</div>
					</div>
				</section>
				<section className="features-style1-area">
					<div className="container">
						<div className="sec-title text-center">
							<h2>Save for a Better Tomorrow</h2>
							<div className="sub-title">
								<p>Committed to helping our customers succeed.</p>
							</div>
						</div>
						<div className="features-style1-content">
							<ul className="clearfix">
								<li>
									<div className="single-features-style1-box">
										<div className="shape-left">
											<img src="assets/images/shapes/shape-1.png" alt="" />
										</div>
										<div className="shape-bottom">
											<img src="assets/images/shapes/shape-2.png" alt="" />
										</div>
										<div className="counting-box">
											<div
												className="counting-box-bg"
												style={{
													backgroundImage:
														'url(assets/images/shapes/counting-box-bg.png)',
												}}
											/>
											<h3>01</h3>
										</div>
										<div className="text-box">
											<h4>Fixed Savings</h4>
											<h3>Our Strategies for Better Returns</h3>
											<div className="btn-box">
												<a href="#">
													Read More
													<i className="fas fa-plus" />
												</a>
											</div>
										</div>
									</div>
								</li>
								<li>
									<div className="single-features-style1-box">
										<div className="shape-left">
											<img src="assets/images/shapes/shape-1.png" alt="" />
										</div>
										<div className="shape-bottom">
											<img src="assets/images/shapes/shape-2.png" alt="" />
										</div>
										<div className="counting-box">
											<div
												className="counting-box-bg"
												style={{
													backgroundImage:
														'url(assets/images/shapes/counting-box-bg.png)',
												}}
											/>
											<h3>02</h3>
										</div>
										<div className="text-box">
											<h4>Target Savings</h4>
											<h3>Savings Solutions for Personal Needs</h3>
											<div className="btn-box">
												<a href="#">
													Read More
													<i className="fas fa-plus" />
												</a>
											</div>
										</div>
									</div>
								</li>
								<li>
									<div className="single-features-style1-box">
										<div className="shape-left">
											<img src="assets/images/shapes/shape-1.png" alt="" />
										</div>
										<div className="shape-bottom">
											<img src="assets/images/shapes/shape-2.png" alt="" />
										</div>
										<div className="counting-box">
											<div
												className="counting-box-bg"
												style={{
													backgroundImage:
														'url(assets/images/shapes/counting-box-bg.png)',
												}}
											/>
											<h3>03</h3>
										</div>
										<div className="text-box">
											<h4>Invest</h4>
											<h3>Our Strategies for Growth</h3>
											<div className="btn-box">
												<a href="#">
													Read More
													<i className="fas fa-plus" />
												</a>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</section>
				<section className="service-style1-area">
					<div
						className="service-style1-bg"
						style={{
							backgroundImage:
								'url(assets/images/backgrounds/service-style1.jpg)',
						}}
					/>
					<div className="container">
						<div className="row">
							<div className="col-xl-12">
								<div className="service-style1-title">
									<div className="sec-title">
										<h2>Saving For Your Needs</h2>
										<div className="sub-title">
											<p>The platform that builds better relationships.</p>
										</div>
									</div>
									<div className="get-assistant-box">
										<a href="#">
											<span className="icon-chatting" />
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xl-12">
								<div className="service-style1-tab">
									<div className="tabs-content-box">
										<div className="tab-content-box-item" id="individuals">
											<div className="service-style1-tab-content-box-item">
												<div className="row">
													<div className="col-xl-4 col-lg-4">
														<div className="single-service-box-style1">
															<h3>
																<a href="#">Savings &amp; Investments</a>
															</h3>
															<div className="border-box" />
															<p>
																We understand that your financial goals are
																unique. Whether you're dreaming of a new car, a
																cozy home, or a worry-free retirement, our
																Savings &amp; Investments feature empowers you
																to take control of your financial journey.
															</p>
															<h6>
																<span>*</span> Interest rate up to 5% p.a
															</h6>
															<div className="btn-box">
																<a href="#">
																	<span className="icon-right-arrow" />
																</a>
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-lg-4">
														<div className="single-service-box-style1">
															<h3>
																<a href="#">Online &amp; Mobile</a>
															</h3>
															<div className="border-box" />
															<p>
																Life moves fast, and so does your financial
																journey with Savebox. Our Online &amp; Mobile
																feature ensures that you're always in control of
																your finances, no matter where you are.
															</p>
															<h6>
																<span>*</span> Terms &amp; Conditions
															</h6>
															<div className="btn-box">
																<a href="#">
																	<span className="icon-right-arrow" />
																</a>
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-lg-4">
														<div className="single-service-box-style1">
															<h3>
																<a href="#">Consumer Loans</a>
															</h3>
															<div className="border-box" />
															<p>
																Choose from a variety of flexible loan options
																tailored to your needs. With competitive
																interest rates and repayment plans, Savebox is
																here to support you in making major life
																purchases without financial stress.
															</p>
															<h6>
																<span>*</span> Check today’s Interest Rates
															</h6>
															<div className="btn-box">
																<a href="#">
																	<span className="icon-right-arrow" />
																</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div
											className="tab-content-box-item tab-content-box-item-active"
											id="companies"
										>
											<div className="service-style1-tab-content-box-item">
												<div className="row">
													<div className="col-xl-4 col-lg-4">
														<div className="single-service-box-style1">
															<h3>
																<a href="#">Savings &amp; Investment</a>
															</h3>
															<div className="border-box" />
															<p>
																We understand that your financial goals are
																unique. Whether you're dreaming of a new car, a
																cozy home, or a worry-free retirement, our
																Savings &amp; Investments feature empowers you
																to take control of your financial journey.
															</p>
															<h6>
																<span>*</span> Interest rate up to 5% p.a
															</h6>
															<div className="btn-box">
																<a href="#">
																	<span className="icon-right-arrow" />
																</a>
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-lg-4">
														<div className="single-service-box-style1">
															<h3>
																<a href="#">Online &amp; Mobile</a>
															</h3>
															<div className="border-box" />
															<p>
																Life moves fast, and so does your financial
																journey with Savebox. Our Online &amp; Mobile
																feature ensures that you're always in control of
																your finances, no matter where you are.
															</p>
															<h6>
																<span>*</span> Terms &amp; Conditions
															</h6>
															<div className="btn-box">
																<a href="#">
																	<span className="icon-right-arrow" />
																</a>
															</div>
														</div>
													</div>
													<div className="col-xl-4 col-lg-4">
														<div className="single-service-box-style1">
															<h3>
																<a href="#">Consumer Loans</a>
															</h3>
															<div className="border-box" />
															<p>
																Choose from a variety of flexible loan options
																tailored to your needs. With competitive
																interest rates and repayment plans, Savebox is
																here to support you in making major life
																purchases without financial stress.
															</p>
															<h6>
																<span>*</span> Check today’s Interest Rates
															</h6>
															<div className="btn-box">
																<a href="#">
																	<span className="icon-right-arrow" />
																</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xl-12">
								<div className="service-style1__btns-box text-center">
									<a className="btn-one" href="#">
										<span className="txt">View All Services</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="faq-style1-area">
					<div className="container">
						<div className="row">
							<div className="col-xl-12">
								<div className="faq-style1-title">
									<div className="sec-title">
										<h2>Questions &amp; Answers</h2>
										<div className="sub-title">
											<p>Find answers to all your queries about our service.</p>
										</div>
									</div>
									<div className="faq-search-box">
										<h3>Search</h3>
										<div className="faq-search-box__inner">
											<form className="search-form" action="#">
												<input placeholder="Related Keyword..." type="text" />
												<button type="submit">
													<i className="icon-search" />
												</button>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
						<div className="col-xl-6">
							<div className="faq-style1__image">
							<div className="inner">
								<img src="assets/images/resources/faq-style1__image.jpg" alt="" />
							</div>
							</div>
						</div>
						<div className="col-xl-6">
							<div className="faq-style1__content">
							<ul className="accordion-box">
								{faqItems.map((item, index) => (
								<li
									key={index}
									className={`accordion block ${index === activeIndex ? 'active-block' : ''}`}
									onClick={() => handleSelect(index)}
								>
									<div className={`acc-btn ${index === activeIndex ? 'active' : ''}`}>
									<div className="icon-outer">
										<i className="icon-right-arrow" />
									</div>
									<h3>{item.question}</h3>
									</div>
									<div className={`acc-content ${index === activeIndex ? 'current' : ''}`}>
									<p>
										<b>Answer:</b> {item.answer}
									</p>
									</div>
								</li>
								))}
							</ul>
							</div>
						</div>
						</div>

						<div className="row">
							<div className="col-xl-12">
								<div className="faq-style1-bottom-box text-center">
									<p>
										Didn’t get, Click below button to more anwers or 
										 <a href="#"> contact us.</a>
									</p>
									<div className="btns-box">
										<a className="btn-one" href="#">
											<span className="txt">Grab Your Deals</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="deals-area">
					<div className="auto-container">
						<div className="deals-content-box">
							<div
								className="owl-carousel owl-theme thm-owl__carousel deals-carousel-one owl-nav-style-one"
								data-owl-options='{
					"loop": true,
					"autoplay": true,
					"margin": 50,
					"nav": true,
					"dots": false,
					"smartSpeed": 500,
					"autoplayTimeout": 10000,
					"navText": ["<span class=\"left icon-right-arrow\"></span>","<span class=\"right icon-right-arrow\"></span>"],
					"responsive": {
							"0": {
								"items": 1
							},
							"768": {
								"items": 1
							},
							"992": {
								"items": 1
							},
							"1550": {
								"items": 1.4
							}
						}
					}'
							>
								<div className="single-deals-box">
									<div className="text-box">
										<div className="inner-title">
											<h4>6 Month Savings Offers</h4>
											<h2>Get Shopping Coupons For Your Favourite Store</h2>
										</div>
										<p>When shopping from our partner stores</p>
										<ul>
											<li>
												<div className="inner">
													<div className="icon">
														<span className="icon-checkbox-mark" />
													</div>
													<div className="text">
														<p>Get a 2% discount</p>
													</div>
												</div>
											</li>
										</ul>
										<div className="btns-box">
											<a className="btn-one" href="#">
												<span className="txt">Grab Your Deals</span>
											</a>
										</div>
									</div>
									<div className="img-box">
										<div
											className="img-bg"
											style={{
												backgroundImage:
													'url(assets/images/resources/deals-2.jpg)',
											}}
										/>
										<div className="shape-left-1" />
										<div className="shape-right-1" />
										<div className="shape-right-2" />
									</div>
								</div>
								<div className="single-deals-box">
									<div className="text-box">
										<div className="inner-title">
											<h4>8 Month Saving Offer</h4>
											<h2>Buy a Mobile Phone</h2>
										</div>
										<p>
											Get your mobile phones and tablets from our partner stores
										</p>
										<ul>
											<li>
												<div className="inner">
													<div className="icon">
														<span className="icon-checkbox-mark" />
													</div>
													<div className="text">
														<p>Get 3% Off</p>
													</div>
												</div>
											</li>
										</ul>
										<div className="btns-box">
											<a className="btn-one" href="#">
												<span className="txt">Grab Your Deals</span>
											</a>
										</div>
									</div>
									<div className="img-box">
										<div
											className="img-bg"
											style={{
												backgroundImage:
													'url(assets/images/resources/deals-1.jpg)',
											}}
										/>
										<div className="shape-left-1" />
										<div className="shape-right-1" />
										<div className="shape-right-2" />
									</div>
								</div>
								<div className="single-deals-box">
									<div className="text-box">
										<div className="inner-title">
											<h4>6 Month Savings Offers</h4>
											<h2>Get Shopping Coupons For Your Favourite Store</h2>
										</div>
										<p>When shopping from our partner stores</p>
										<ul>
											<li>
												<div className="inner">
													<div className="icon">
														<span className="icon-checkbox-mark" />
													</div>
													<div className="text">
														<p>Get a 2% discount</p>
													</div>
												</div>
											</li>
										</ul>
										<div className="btns-box">
											<a className="btn-one" href="#">
												<span className="txt">Grab Your Deals</span>
											</a>
										</div>
									</div>
									<div className="img-box">
										<div
											className="img-bg"
											style={{
												backgroundImage:
													'url(assets/images/resources/deals-2.jpg)',
											}}
										/>
										<div className="shape-left-1" />
										<div className="shape-right-1" />
										<div className="shape-right-2" />
									</div>
								</div>
								<div className="single-deals-box">
									<div className="text-box">
										<div className="inner-title">
											<h4>8 Month Saving Offer</h4>
											<h2>Buy a Mobile Phone</h2>
										</div>
										<p>
											Get your mobile phones and tablets from our partner stores
										</p>
										<ul>
											<li>
												<div className="inner">
													<div className="icon">
														<span className="icon-checkbox-mark" />
													</div>
													<div className="text">
														<p></p>
														<p>Get 3% Off</p>
														<p />
													</div>
												</div>
											</li>
										</ul>
										<div className="btns-box">
											<a className="btn-one" href="#">
												<span className="txt">Grab Your Deals</span>
											</a>
										</div>
									</div>
									<div className="img-box">
										<div
											className="img-bg"
											style={{
												backgroundImage:
													'url(assets/images/resources/deals-1.jpg)',
											}}
										/>
										<div className="shape-left-1" />
										<div className="shape-right-1" />
										<div className="shape-right-2" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<footer className="footer-area">
					<div className="right-shape">
						<img src="assets/images/shapes/footer-right-shape.png" alt="" />
					</div>
					<div className="footer-top">
						<div className="lef-shape">
							<span className="icon-origami" />
						</div>
						<div className="container">
							<div className="row">
								<div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 single-widget">
									<div className="single-footer-widget single-footer-widget--link-box">
										<div className="title">
											<h3>Loans</h3>
										</div>
										<div className="footer-widget-links">
											<ul>
												<li>
													<a href="#">Home Loan</a>
												</li>
												<li>
													<a href="#">Car Loan</a>
												</li>
												<li>
													<a href="#">Start-Up Loan</a>
												</li>
												<li>
													<a href="#">Commercial Vehicle Loan</a>
												</li>
												<li>
													<a href="#">Personal Loan</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
								{/* <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 single-widget">
									<div className="single-footer-widget single-footer-widget--link-box">
										<div className="title">
											<h3>Rates &amp; Charges</h3>
										</div>
										<div className="footer-widget-links">
											<ul>
												<li>
													<a href="#">Interest Rates</a>
												</li>
												<li>
													<a href="#">Service Charges &amp; Fees</a>
												</li>
											</ul>
										</div>
									</div>
								</div> */}
								<div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 single-widget">
									<div className="single-footer-widget single-footer-widget--link-box">
										<div className="title">
											<h3>Services</h3>
										</div>
										<div className="footer-widget-links">
											<ul>
												<li>
													<a href="#">Automated Savings</a>
												</li>
												<li>
													<a href="#">Flexible Savings</a>
												</li>
												<li>
													<a href="#">Target Savings</a>
												</li>
												<li>
													<a href="#">Investment</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer">
						<div className="container">
							<div className="row">
								<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
									<div className="single-footer-widget marbtm50">
										<div className="our-company-info">
											<div className="footer-logo-style1">
												<a href="index.html">
													<img
														src="assets/images/logo-1.png"
														alt="Savebox"
														style={{
															padding: '0 !important',
															marginLeft: '-17px !important',
														}}
													/>
												</a>
											</div>
											<div className="copyright-text">
												<p style={{marginLeft:18}}>
													© {new Date().getFullYear()}
													<a href="index.html"> Freewill Thrift & Credit</a>
												</p>
												<p style={{marginLeft:18}}>Powered by <a href='https://cyrusnet4d.com' target='_blank'><span style={{color:'#8a0f3d'}}>Cyrusnet Technologies</span></a></p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
									<div className="single-footer-widget marbtm50">
										<div className="footer-widget-contact-info">
											<ul>
												<li>
													<h3>
														<a href="tel:2348077756784">234-701-1115-274</a>
													</h3>
													<p>Customer Care</p>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
									<div className="single-footer-widget">
										<div className="single-footer-widget-right-colum">
											<ul>
												<li>
													<a href="#">
														Download Forms
														<span className="icon-download" />
													</a>
												</li>
												<li>
													<a href="#">
														Make Complaint
														<span className="icon-feedback" />
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="container">
							<div className="bottom-inner">
								<div className="footer-menu">
									<ul>
										<li>
											<a href="#">Disclaimer</a>
										</li>
										<li>
											<a href="#">Privacy Policy</a>
										</li>
										<li>
											<a href="#">Terms &amp; Conditions</a>
										</li>
										<li>
											<a href="#">Online Security Tips</a>
										</li>
									</ul>
								</div>
								<div className="footer-social-link">
									<ul className="clearfix">
										<li>
											<a href="#">
												<i className="fab fa-youtube" />
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-instagram" />
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-twitter" />
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-facebook-f" />
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</div>
			<div className="mobile-nav__wrapper">
				<div className="mobile-nav__overlay mobile-nav__toggler" />
				<div className="mobile-nav__content">
					<span className="mobile-nav__close mobile-nav__toggler">
						<i className="fas fa-plus" />
					</span>
					<div className="logo-box">
						<a href="index.html" aria-label="logo image">
							<img src="assets/images/logo-1.png" alt="" />
						</a>
					</div>
					<div className="mobile-nav__container" />
					<ul className="mobile-nav__contact list-unstyled">
						<li>
							<i className="fa fa-envelope" />
							<a href="mailto:info@freewillsavings.ng">info@freewillsavings.ng</a>
						</li>
						<li>
							<i className="fa fa-phone-alt" />
							<a href="tel:2347011115274">234-701-1115-274</a>
						</li>
					</ul>
					<div className="mobile-nav__social">
						<a href="#" className="fab fa-twitter" />
						<a href="#" className="fab fa-facebook-square" />
						<a href="#" className="fab fa-pinterest-p" />
						<a href="#" className="fab fa-instagram" />
					</div>
				</div>
			</div>
			<div className="search-popup">
				<div className="search-popup__overlay search-toggler" />
				<div className="search-popup__content">
					<form action="#">
						<label htmlFor="search" className="sr-only">
							search here
						</label>
						<input type="text" id="search" placeholder="Search Here..." />
						<button
							type="submit"
							aria-label="search submit"
							className="thm-btn"
						>
							<i className="icon-search" />
						</button>
					</form>
				</div>
			</div>
			<a href="#" data-target="html" className="scroll-to-target scroll-to-top">
				<i className="icon-chevron" />
			</a>
		</div>
	);
}

export default HomePage;
