import React, { useState, useRef } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';

const Register = () => {
	const history = useHistory();
	const fullNameRef = useRef();
	// const phoneNumRef = useRef();
	const emailRef = useRef();
	const passwordRef = useRef();
	const passwordConfirmRef = useRef();
	const [registerInput, setRegister] = useState({
		fullname: '',
		email: '',
		password: '',
		password_confirmation: '',
	});
	const handleInput = (e) => {
		e.persist();
		setRegister({ ...registerInput, [e.target.name]: e.target.vaue });
	};

	// if ((fullNameRef.current.value = null)) {
	// 	fullNameRef.current.value = '';
	// }

	// if ((emailRef.current.value = null)) {
	// 	emailRef.current.value = '';
	// }

	// if ((passwordRef.current.value = null)) {
	// 	passwordRef.current.value = '';
	// }

	const registerSubmit = (e) => {
		e.preventDefault();

		const data = {
			fullname: fullNameRef.current.value,
			email: emailRef.current.value,
			password: passwordRef.current.value,
			password_confirmation: passwordConfirmRef.current.value,
		};

		axios.get('/sanctum/csrf-cookie').then((response) => {
			axios.post(`api/register`, data).then((res) => {
				console.warn(res.data);
				if (res.data.status === 200) {
					localStorage.setItem('auth-token', res.data.token);
					localStorage.setItem('auth-email', res.data.email);
					Swal.fire({
						icon: 'success',
						title: 'Registration',
						text: 'Successful!',
					});
					history.push('/');
				} else {
					Swal.fire({
						icon: 'error',
						title: 'Registration',
						text: 'Failed!',
					});
				}
			});
		});
	};

	return (
		<div>
			<div className="container">
				<div className="row">
					<div className="col-md-10 ml-auto mr-auto">
						<div className="card card-signup">
							<h2 className="card-title text-center">Register</h2>
							<div className="card-body">
								<div className="row">
									<div className="col-md-5 ml-auto">
										<div className="info info-horizontal">
											<div className="icon icon-rose">
												<i className="material-icons">timeline</i>
											</div>
											<div className="description">
												<h4 className="info-title">Marketing</h4>
												<p className="description">
													We've created the marketing campaign of the website.
													It was a very interesting collaboration.
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-5 mr-auto">
										<div className="social text-center">
											<button className="btn btn-just-icon btn-round btn-twitter">
												<i className="fa fa-twitter" />
											</button>
											<button className="btn btn-just-icon btn-round btn-dribbble">
												<i className="fa fa-dribbble" />
											</button>
											<button className="btn btn-just-icon btn-round btn-facebook">
												<i className="fa fa-facebook"> </i>
											</button>
											<h4 className="mt-3"> or be classical </h4>
										</div>
										<form className="form">
											<div className="form-group has-default">
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text">
															<i className="material-icons">face</i>
														</span>
													</div>
													<input
														onChange={handleInput}
														ref={fullNameRef}
														// value={registerInput.fullname}
														type="text"
														className="form-control"
														placeholder="Full Name..."
													/>
												</div>
											</div>
											<div className="form-group has-default">
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text">
															<i className="material-icons">mail</i>
														</span>
													</div>
													<input
														onChange={handleInput}
														ref={emailRef}
														// value={registerInput.email}
														type="text"
														className="form-control"
														placeholder="Email..."
													/>
												</div>
											</div>
											<div className="form-group has-default">
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text">
															<i className="material-icons">lock_outline</i>
														</span>
													</div>
													<input
														onChange={handleInput}
														ref={passwordRef}
														// value={registerInput.password}
														type="password"
														placeholder="Password..."
														className="form-control"
													/>
												</div>
											</div>
											<div className="form-group has-default">
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text">
															<i className="material-icons">lock_outline</i>
														</span>
													</div>
													<input
														onChange={handleInput}
														ref={passwordConfirmRef}
														// value={registerInput.password}
														type="password"
														placeholder="Repeat Password..."
														className="form-control"
													/>
												</div>
											</div>
											<div className="form-check">
												<label className="form-check-label">
													<input
														onChange={handleInput}
														className="form-check-input"
														type="checkbox"
														// defaultValue
														defaultChecked
													/>
													<span className="form-check-sign">
														<span className="check" />
													</span>
													I agree to the
													<Link to="/terms">terms and conditions</Link>.
												</label>
											</div>
											<div className="text-center">
												<Link
													className="btn btn-primary btn-round mt-4"
													onClick={registerSubmit}
												>
													Get Started
												</Link>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Register;
