import { Link, useHistory } from 'react-router-dom';
import { Container, Button, Form, Row, Col } from 'react-bootstrap';
import classes from '../components/layouts/Login.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import target from '../img/target.png';
import { useRef, useState, useEffect } from 'react';
import Swal from 'sweetalert2';

function ForgotPasswordPage(props) {
	//function LoginPage() {
	const emailRef = useRef();
	const passwordRef = useRef();
	const history = useHistory();
	//const MySwal = withReactContent(Swal);
	let usertoken = '';

	function useWrappedEffect() {
		const [runEffect, setRunEffect] = useState(false);

		useEffect(() => {
			if (runEffect) {
				history.push('/dashboard');
				usertoken = sessionStorage.getItem('token');
				props.func(usertoken);
				setRunEffect(false);
			}
		}, [runEffect]);

		return {
			run: () => {
				setRunEffect(true);
			},
		};
	}

	const myEffect = useWrappedEffect(); // get a reference to the hook
	const [run, setRun] = useState(false);

	if (run) {
		myEffect.run();
		setRun(false);
	}

	async function handleSubmit(e) {
		e.preventDefault();

		const email = emailRef.current.value;
		const password = passwordRef.current.value;
		try {
			let user = {
				email,
				password,
			};

			let result = await fetch(
				'https://savebox.cyrusnet4d.com/public/api/login',
				{
					method: 'POST',
					body: JSON.stringify(user),
					headers: {
						//'Authorization':'Bearer '.$token,
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
				},
			);
			result = await result.json();
			//console.warn(result);
			const token = result.token;
			const user_details = result;
			// console.warn(user_details.user.acct_balance);
			if (token !== undefined) {
				window.sessionStorage.setItem('token', token);
				window.localStorage.setItem('auth-token', token);
				window.sessionStorage.setItem('user_email', user_details.user.email);
				window.sessionStorage.setItem(
					'user_phone',
					user_details.user.phone_number,
				);
				window.sessionStorage.setItem(
					'user_fullname',
					user_details.user.full_name,
				);
				window.sessionStorage.setItem(
					'user_wallet_id',
					user_details.user.account_number,
				);
				window.sessionStorage.setItem(
					'wallet_balance',
					user_details.user.acct_balance,
				);
				window.sessionStorage.setItem(
					'available_balance',
					user_details.user.available_bal,
				);
				setRun(true);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Login',
					text: 'Failed!',
				});
			}
		} catch {
			Swal.fire({
				icon: 'error',
				title: 'Login',
				text: 'System failure!',
			});
		}
	}

	return (
		<Container className={classes.login}>
			<Row className="justify-content-md-center">
				<Col className={classes.sidetext}>
					<h3>Lost access? No worries!</h3>
					<img src={target} alt="" className={classes.loginimg} />
				</Col>
				<Col className={classes.loginform}>
					<Form className={classes.form} onSubmit={handleSubmit}>
						<Form.Group controlId="formBasicEmail">
							<Form.Label>Email address | Phone Number</Form.Label>
							<Form.Control
								className="form-control border"
								placeholder="Enter email or phone number"
								ref={emailRef}
								required
							/>
						</Form.Group>

						<Button
							variant="primary"
							className={classes.loginbtn}
							type="submit"
						>
							Reset account
						</Button>
						<p>
							<span>
								<Link className={classes.nav} to="/sign-up">
									Register Now
								</Link>
							</span>
							<br />
							<span>
								<Link className={classes.nav} to="/login">
									Login
								</Link>
							</span>
						</p>
					</Form>
				</Col>
			</Row>
		</Container>
	);
}

export default ForgotPasswordPage;
