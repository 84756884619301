import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const app = firebase.initializeApp({
	apiKey: 'AIzaSyCmn7sFg1qWqomSvc0a4r6n3cd669Cftog',
	authDomain: 'savebox-ed4b1.firebaseapp.com',
	projectId: 'savebox-ed4b1',
	storageBucket: 'savebox-ed4b1.appspot.com',
	messagingSenderId: '1082862937354',
	appId: '1:1082862937354:web:aa0a398d67f3ce3bbbc6f3',
	measurementId: 'G-DEFPLM1S74',
});

export const auth = app.auth();
export default app;
