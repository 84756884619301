import { Route, Switch, Redirect } from 'react-router-dom';
import { useState } from 'react';
import GetStartedPage from './pages/GetStarted';
import SetGoalsPage from './pages/SetGoals';
import LoginPage from './pages/Login';
import SignUpPage from './pages/SignUp';
import ForgotPasswordPage from './pages/FogotPassword';
import DashboardPage from './pages/frontend/Dashboard';
import ActivitiesPage from './pages/Activities';
import WalletPage from './pages/frontend/Wallet';
import MakeContributionPage from './pages/frontend/Contribute';
import MakeWithdrawalsPage from './pages/frontend/Withdraw';
import AddPlanPage from './pages/frontend/AddPlan';
import MakeTransactionsPage from './pages/frontend/Transactions';
import MakeTransferPage from './pages/frontend/Transfer';
import FaqPage from './pages/Faq';
import ApiPage from './pages/Api';
import HomePage from './pages/Home';
import MainNavigation from './components/layouts/MainNavigation';
// import NewNavigation from './components/layouts/NewNavigation';
// import FrontMainNavigation from './components/layouts/FrontMainNavigation';
// import FooterNavigation from './components/layouts/FooterNavigation';
import { AuthProvider } from './AuthContext';
import axios from 'axios';
import Register from './pages/frontend/auth/Register';
import Login from './pages/frontend/auth/Login';
// import { Redirect } from 'react-router-dom';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://savebox.freewillsavings.ng/public/';
axios.defaults.headers.post['Content-Type'] = 'Application/json';
axios.defaults.headers.post['Accept'] = 'Application/json';
//const token = sessionStorage.getItem('token');
//axios.defaults.headers.common = { Authorization: `bearer ${token}` };
axios.interceptors.request.use(function (config) {
	const token = localStorage.getItem('auth-token');
	config.headers.Authorization = token ? `Bearer ${token}` : '';
	return config;
});

function App() {
	const [token, setToken] = useState('');
	const pull_data = (data) => {
		setToken(data);
		console.log(token);
	};

	return (
		<div>
			<AuthProvider>
				<MainNavigation />
				{/* <NewNavigation /> */}
				{/* <FrontMainNavigation /> */}
				<Switch>
					<Route path="/" exact>
						<HomePage />
					</Route>
					<Route path="/get-started">
						<GetStartedPage />
					</Route>
					<Route path="/set-goals">
						<SetGoalsPage />
					</Route>
					<Route path="/faq">
						<FaqPage />
					</Route>
					<Route path="/api-doc">
						<ApiPage />
					</Route>
					{/* <Route path="/login">
						<LoginPage />
					</Route> */}
					{/*<Route path="/login">
						<LoginPage func={pull_data} />
					</Route>*/}
					<Route path="/login">
						{() => {
						const authToken = localStorage.getItem('auth-token');
						if (authToken) {
							console.log(authToken);
							// window.location.href = 'https://www.example.com/dashboard'; // Change to your external URL
						} else {
							window.location.href = 'https://savebox.freewillsavings.ng'; // Change to your external URL
						}
						return null; // Return null to stop rendering
						}}
					</Route>

					{/* <Route path="/sign-up">
						<SignUpPage />
					</Route> */}
					<Route path="/activities">
						<ActivitiesPage />
					</Route>
					<Route path="/wallet">
						<WalletPage />
					</Route>
					<Route path="/dashboard">
						<DashboardPage />
					</Route>
					<Route path="/transactions">
						<MakeTransactionsPage />
					</Route>
					<Route path="/contribute">
						{!localStorage.getItem('auth-token') ? (
							<Redirect to="/" />
						) : (
							<MakeContributionPage />
						)}
					</Route>
					<Route path="/withdraw">
						<MakeWithdrawalsPage />
					</Route>
					<Route path="/transfer">
						<MakeTransferPage />
					</Route>
					<Route path="/forgot-password">
						<ForgotPasswordPage />
					</Route>
					<Route path="/register">
						<Register />
					</Route>
					<Route path="/add-plan">
						<AddPlanPage />
					</Route>
					<Route path="/sign-in">
						<Login />
					</Route>
				</Switch>
				{/* <FooterNavigation /> */}
			</AuthProvider>
		</div>
	);
}

export default App;
