import { Redirect, Link, useHistory } from 'react-router-dom';
// import { Container, Button, Form, Row, Col } from 'react-bootstrap';
import classes from '../../components/layouts/Dashboard.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
// import Swal from 'sweetalert2';
import axios from 'axios';
//const url = 'https://savebox.cyrusnet4d.com/public/api/fetch_data';
function DashboardPage() {
	const history = useHistory();
	const usertoken = sessionStorage.getItem('token');
	let user_fullname = sessionStorage.getItem('user_fullname');
	let wallet_balance = sessionStorage.getItem('wallet_balance');
	let user_wallet_id = sessionStorage.getItem('user_wallet_id');

	// const [targets, setTargets] = useState(0);
	const [savings, setSavings] = useState(0);
	const [transactions, setTransactions] = useState();
	const [loading, setLoading] = useState(true);
	const [check, setCheck] = useState(true);
	const [list, setList] = useState('');
	const [savingDays, setSavingDays] = useState();
	// const [targetProgress, setTargetProgress] = useState();
	// const [targetPlan, setTargetPlan] = useState();
	const [walletBalance, setWalletBalance] = useState();
	const [availableBalance, setAvailableBalance] = useState();

	// const planTypeRef = useRef();
	// const planTypeRef1 = useRef();
	// const planNameRef = useRef();
	// const planNameRef1 = useRef();
	// const planCategoryRef = useRef();
	// const planAmountRef = useRef();
	// const planAmountRef1 = useRef();

	var savingsDetail = { ...list.slice(0, 1) };
	// var targetsDetail = { ...list.slice(1, 2) };

	var saving_days = '';
	//var target_days = '';

	if (savingsDetail[0] !== undefined) {
		saving_days = savingsDetail[0].total_days;
	}

	// if (targetsDetail[0] !== undefined) {
	// 	target_days = targetsDetail[0].total_days;
	// }

	useEffect(() => {
		if (!user_wallet_id) {
			history.push('/');
		}
		const data = {
			user_wallet_id: user_wallet_id,
		};

		axios
			.post(`api/fetch_savings_data`, data)
			.then((res) => {
				setSavings(res.data.wallet_details.length);
			})
			.catch((err) => {
				console.log(err);
			});

		// axios
		// 	.post(`api/fetch_targets_data`, data)
		// 	.then((res) => {
		// 		setTargets(res.data.wallet_details.length);
		// 	})
		// 	.catch((err) => {
		// 		console.log(err);
		// 	});

		axios
			.post(`api/wallet_balance`, data)
			.then((res) => {
				setWalletBalance(res.data);
				// console.log(res.data);
			})
			.catch((err) => {
				console.log(err);
			});

		axios
			.post(`api/available_balance`, data)
			.then((res) => {
				setAvailableBalance(res.data);
				sessionStorage.setItem('available_balance', res.data);
				// console.log(res.data);
			})
			.catch((err) => {
				console.log(err);
			});

		axios
			.post(`api/fetch_saving_days`, data)
			.then((res) => {
				setSavingDays(res.data);
			})
			.catch((err) => {
				console.log(err);
			});

		// axios
		// 	.post(`api/fetch_target_plan`, data)
		// 	.then((res) => {
		// 		setTargetPlan(res.data);
		// 		//console.log(res.data);
		// 	})
		// 	.catch((err) => {
		// 		console.log(err);
		// 	});

		// axios
		// 	.post(`api/fetch_target_progress`, data)
		// 	.then((res) => {
		// 		setTargetProgress(res.data);
		// 		//console.log(res.data);
		// 	})
		// 	.catch((err) => {
		// 		console.log(err);
		// 	});

		axios
			.post(`api/fetch_plan_days`, data)
			.then((res) => {
				setList(res.data);
			})
			.catch((err) => {
				console.log(err);
			});

		axios
			.post(`api/fetch_transactions_data`, data)
			.then((res) => {
				window.sessionStorage.setItem('trans_data', res.data.wallet_details);
				if (window.sessionStorage.getItem('trans_data')) {
					if (transactions === undefined) {
						//console.log(transactions);
						setTransactions(res.data.wallet_details);
						setLoading(false);
						// setCheck(false);
					}
				} else {
					//setTransactions(res.data.wallet_details);
					setLoading(false);
					// setCheck(false);
				}
			})
			.catch((err) => {
				console.log(err);
			});
		setCheck(false);
		// return () => {
		// 	// setTransactions();
		// 	// setList();
		// 	// setSavingDays();
		// 	// setSavings();
		// 	// setTargetPlan();
		// 	// setTargetProgress();
		// 	// setTargets();
		// };
	}, [transactions, user_wallet_id, check, history]);

	// function clear() {
	// 	//planTypeRef.current.value =
	// 	planNameRef.current.value =
	// 		planNameRef1.current.value =
	// 		planAmountRef.current.value =
	// 		planAmountRef1.current.value =
	// 			'';
	// 	planCategoryRef.current.value = 'Select plan category...';
	// }

	if (isNaN(wallet_balance)) {
		wallet_balance = 0;
	}

	var first_name = '';
	if (!usertoken || usertoken === null) {
	} else {
		var splitter = user_fullname.split(' ');
		first_name = splitter[1];

		<Redirect to="/dashboard" />;
		//history.push('/dashboard');
	}

	// var getDaysInMonth = function (month, year) {
	// 	return new Date(year, month, 0).getDate();
	// };

	// const d = new Date();
	// let month = d.getMonth() + 1;
	// const currentYear = new Date().getFullYear();

	// async function handleSubmit(e) {
	// 	e.preventDefault();

	// 	var plan_type = planTypeRef.current.value;
	// 	var plan_name = planNameRef.current.value;
	// 	var plan_category = planCategoryRef.current.value;
	// 	var plan_amount = planAmountRef.current.value;
	// 	var plan_start = new Date().toLocaleDateString('en-GB');
	// 	var plan_end = new Date();
	// 	var plan_days;
	// 	var plan_daily;

	// 	if (!plan_name) {
	// 		plan_type = planTypeRef1.current.value;
	// 		plan_name = planNameRef1.current.value;
	// 	}

	// 	if (!plan_amount) {
	// 		plan_amount = planAmountRef1.current.value;
	// 	}

	// 	if (plan_category === 'starter') {
	// 		plan_end.setMonth(plan_end.getMonth() + 1);
	// 		plan_days = getDaysInMonth(month, currentYear);
	// 		plan_daily = plan_amount / plan_days;
	// 	} else if (plan_category === 'classic') {
	// 		plan_end.setMonth(plan_end.getMonth() + 3);
	// 		plan_days =
	// 			getDaysInMonth(month, currentYear) +
	// 			getDaysInMonth(month + 1, currentYear) +
	// 			getDaysInMonth(month + 2, currentYear);
	// 		plan_daily = plan_amount / plan_days;
	// 	} else if (plan_category === 'prime') {
	// 		plan_end.setMonth(plan_end.getMonth() + 6);
	// 		plan_days =
	// 			getDaysInMonth(month, currentYear) +
	// 			getDaysInMonth(month + 1, currentYear) +
	// 			getDaysInMonth(month + 2, currentYear) +
	// 			getDaysInMonth(month + 3, currentYear) +
	// 			getDaysInMonth(month + 4, currentYear) +
	// 			getDaysInMonth(month + 5, currentYear);
	// 		plan_daily = plan_amount / plan_days;
	// 	} else {
	// 		plan_end.setMonth(plan_end.getMonth() + 12);
	// 		plan_days =
	// 			getDaysInMonth(month, currentYear) +
	// 			getDaysInMonth(month + 1, currentYear) +
	// 			getDaysInMonth(month + 2, currentYear) +
	// 			getDaysInMonth(month + 3, currentYear) +
	// 			getDaysInMonth(month + 4, currentYear) +
	// 			getDaysInMonth(month + 5, currentYear) +
	// 			getDaysInMonth(month + 6, currentYear) +
	// 			getDaysInMonth(month + 7, currentYear) +
	// 			getDaysInMonth(month + 8, currentYear) +
	// 			getDaysInMonth(month + 9, currentYear) +
	// 			getDaysInMonth(month + 10, currentYear) +
	// 			getDaysInMonth(month + 11, currentYear);
	// 		plan_daily = plan_amount / plan_days;
	// 	}
	// 	plan_end = plan_end.toLocaleDateString('en-GB');
	// 	if (plan_type === 'Targets') {
	// 		plan_category = plan_end = null;
	// 	}

	// 	try {
	// 		let plan = {
	// 			plan_type,
	// 			plan_name,
	// 			plan_category,
	// 			plan_amount,
	// 			user_wallet_id,
	// 			plan_start,
	// 			plan_end,
	// 			plan_days,
	// 			plan_daily,
	// 		};

	// 		let result = await fetch(
	// 			'https://savebox.cyrusnet4d.com/public/api/register_plan',
	// 			{
	// 				method: 'POST',
	// 				body: JSON.stringify(plan),
	// 				headers: {
	// 					'Content-Type': 'application/json',
	// 					Accept: 'application/json',
	// 				},
	// 			},
	// 		);
	// 		clear();
	// 		setCheck(true);
	// 		result = await result.json();
	// 		if (result.account_no) {
	// 			Swal.fire({
	// 				icon: 'success',
	// 				title: 'Plan',
	// 				text: 'Added Successfully!',
	// 			});
	// 		} else {
	// 			Swal.fire({
	// 				icon: 'error',
	// 				title: 'Plan',
	// 				text: 'Please try again!',
	// 			});
	// 		}
	// 	} catch {}
	// }

	if (loading) {
		return (
			<div className={classes.dashboard}>
				<p className={classes.loading}></p>
				<p className={classes.loadingText}>Please wait...</p>
			</div>
		);
	} else {
		var showTableData = '';
		var showPercentageSavingData = '';
		// var showPercentageTargetData = '';

		if (transactions) {
			showTableData = transactions.map((item, idx) => {
				return (
					<tr key={idx}>
						<td>{item.trans_date}</td>
						<td>
							<Link to="#">{item.trans_id}</Link>
						</td>

						{/* {item.beneficiary_id} */}

						{item.beneficiary_id && item.sender_id ? (
							<td>
								{item.trans_type.charAt(0).toUpperCase() +
									item.trans_type.slice(1)}
							</td>
						) : (
							<td>
								<span>Wallet</span>
								{/* <span>Wallet Debit</span> */}
							</td>
						)}

						<td>{item.amount}</td>
						<td>{item.trans_desc}</td>

						{item.beneficiary_id === user_wallet_id ? (
							<td>
								<span className="badge badge-secondary">Received</span>
							</td>
						) : (
							<td>
								<span className="badge badge-dark">Successful</span>
							</td>
						)}
					</tr>
				);
			});

			showPercentageSavingData = (savingDays / saving_days) * 100;
			if (isNaN(showPercentageSavingData)) {
				showPercentageSavingData = 0;
			}

			// showPercentageTargetData = (targetProgress / targetPlan) * 100;
			// if (isNaN(showPercentageTargetData)) {
			// 	showPercentageTargetData = 0;
			// }
		}
	}

	return (
		<div className={classes.dashboard}>
			<div className="container">
				<div className="content-header">
					<div className="container-fluid">
						<div className="row mb-2">
							<div className="col-sm-6">
								<h3 className="m-0">Dashboard</h3>
								<p>Hello, {first_name}</p>
							</div>

							<div className="col-sm-6">
								<ol className="breadcrumb float-sm-right">
									<li className="breadcrumb-item">
										<Link to={''}>Home</Link>
									</li>
									<li className="breadcrumb-item active">Dashboard</li>
								</ol>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-6">
								<p>
									Wallet ID:
									<span className={classes.wallet_id}> {user_wallet_id}</span>
								</p>
							</div>
						</div>
					</div>
				</div>
				<section className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12 col-sm-6 col-md-4">
								<div className={classes.contributebtn}>
									<Link to="/contribute">Contribute Now</Link>
								</div>
							</div>

							<div className="col-12 col-sm-6 col-md-4">
								{availableBalance > 0 ? (
									<div className={classes.contributebtn}>
										<Link to="/withdraw">Withdraw</Link>
									</div>
								) : (
									<div className={classes.contributebtn}>
										<Link to="#">Withdraw</Link>
									</div>
								)}
							</div>
							<div className="col-12 col-sm-6 col-md-4">
								{availableBalance > 0 ? (
									<div className={classes.contributebtn}>
										<Link to="/transfer">Transfer</Link>
									</div>
								) : (
									<div className={classes.transferbtn}>
										<Link to="#">Transfer</Link>
									</div>
								)}
								{/* <div className={classes.transferbtn}>
									<a href="/transfer">Transfer</a>
								</div> */}
							</div>
						</div>
					</div>
					<div className="container-fluid">
						<div className="row">
							<div className="col-12 col-sm-6 col-md-4">
								<div className="info-box">
									<span className="info-box-icon bg-danger elevation-1">
										<i className="fas fa-book-open" />
									</span>
									<div className="info-box-content">
										<span className="info-box-text">
											<span className={classes.balanceText}>
												Wallet Balance
												<br />
												<strong>
													&#8358;
													{!availableBalance
														? 0
														: availableBalance
																.toFixed(2)
																.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
												</strong>
											</span>
										</span>
										<span className={classes.wallet}></span>
									</div>
								</div>
							</div>

							<div className="col-12 col-sm-6 col-md-4">
								<div className="info-box">
									<span className="info-box-icon bg-warning elevation-1">
										<i className="fas fa-book" />
									</span>
									<div className="info-box-content">
										<span className="info-box-text">
											<span className={classes.balanceText}>
												Savebox Balance <br />
												<strong>
													&#8358;
													{!walletBalance
														? 0
														: walletBalance
																.toFixed(2)
																.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
												</strong>
											</span>
										</span>
										<span className={classes.wallet}></span>
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-6 col-md-4">
								<div className="info-box mb-3">
									<span className="info-box-icon bg-danger elevation-1">
										<i className="fas fa-cubes" />
									</span>
									<div className="info-box-content">
										<span className="info-box-text">Active Savings Plan</span>
										<span className="info-box-number">{savings}</span>
										<span className={classes.targets}>
											{/* <button
												className="btn btn-sm btn-danger float-right"
												data-toggle="modal"
												data-target="#modal-savings"
											>
												Add Savings Plan
											</button> */}
											<Link
												className="btn btn-sm btn-danger float-right"
												to="/add-plan"
											>
												Add Savings Plan
											</Link>
										</span>
									</div>
								</div>
							</div>
							{/* <div className="col-12 col-sm-6 col-md-3">
								<div className="info-box mb-3">
									<span className="info-box-icon bg-warning elevation-1">
										<i className="fas fa-dot-circle" />
									</span>
									<div className="info-box-content">
										<span className="info-box-text">Active Target Plan</span>
										<span className="info-box-number">{targets}</span>
										<span className={classes.targets}>
											<button
												className="btn btn-sm btn-warning float-right"
												data-toggle="modal"
												data-target="#modal-targets"
											>
												Add Target Plan
											</button>
										</span>
									</div>
								</div>
							</div> */}
						</div>
						<div className="row">
							<div className="col-md-8">
								<div className="card">
									<div className="card-header border-transparent">
										<h3 className="card-title">Recent Transactions</h3>
										<div className="card-tools">
											<button
												type="button"
												className="btn btn-tool"
												data-card-widget="collapse"
											>
												<i className="fas fa-minus" />
											</button>
											<button
												type="button"
												className="btn btn-tool"
												data-card-widget="remove"
											>
												<i className="fas fa-times" />
											</button>
										</div>
									</div>
									<div className="card-body p-0">
										<div className="table-responsive">
											<table className={classes.table}>
												<thead>
													<tr>
														<th width="15%">Date/Time</th>
														<th>Transaction ID</th>
														<th>Transaction Type</th>
														<th>Transaction Amount</th>
														<th>Transaction Naration</th>
														<th>Status</th>
													</tr>
												</thead>
												<tbody>
													{!showTableData ? <tr></tr> : showTableData}
												</tbody>
											</table>
										</div>
									</div>
									<div className="card-footer clearfix">
										{/* <button
											className="btn btn-sm btn-secondary float-right"
											data-toggle="modal"
											data-target="#modal-transactions"
										>
											View All Transactions
										</button> */}
										<Link
											className="btn btn-sm btn-secondary float-right"
											to="/transactions"
										>
											View All Transactions
										</Link>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="card">
									<div className="card-header">
										<h5 className="card-title">Monthly Recap Report</h5>
										<div className="card-tools">
											<button
												type="button"
												className="btn btn-tool"
												data-card-widget="collapse"
											>
												<i className="fas fa-minus" />
											</button>

											<button
												type="button"
												className="btn btn-tool"
												data-card-widget="remove"
											>
												<i className="fas fa-times" />
											</button>
										</div>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-md-12">
												<p className="text-center">
													<strong>Goal Completion</strong>
												</p>
												<div className="progress-group">
													Savings Plan
													<span className="float-right">
														<b>{!savingDays ? 0 : savingDays}</b>/
														{!saving_days ? 0 : saving_days}
													</span>
													<div className="progress progress-sm">
														<div
															className="progress-bar bg-warning"
															style={{
																width:
																	Math.round(showPercentageSavingData) + `%`,
															}}
														/>
													</div>
												</div>
												{/* <div class="modal1 mfp-hide" id="modal-login">
													<div
														class="block mx-auto"
														style="background-color: #FFF; max-width: 400px;"
													>
														<div style="padding: 50px;">
															<h3 class="font-body">Login to your Account</h3>
															<form action="#" method="post" class="row mb-0">
																<div class="col-12 form-group">
																	<label
																		class="font-body text-capitalize"
																		for="login-form-modal-username"
																	>
																		Username:
																	</label>
																	<input
																		type="text"
																		id="login-form-modal-username"
																		name="login-form-modal-username"
																		value=""
																		class="form-control"
																	/>
																</div>

																<div class="col-12 form-group">
																	<label
																		class="font-body text-capitalize"
																		for="login-form-modal-password"
																	>
																		Password:
																	</label>
																	<input
																		type="password"
																		id="login-form-modal-password"
																		name="login-form-modal-password"
																		value=""
																		class="form-control"
																	/>
																</div>

																<div class="col-12 form-group">
																	<button
																		class="button button-rounded m-0"
																		id="login-form-modal-submit"
																		name="login-form-modal-submit"
																		value="login"
																	>
																		Login
																	</button>
																	<a href="#" class="float-end">
																		Forgot Password?
																	</a>
																</div>
															</form>
															<div class="line line-sm"></div>
															<a
																href="#"
																class="button button-rounded w-100 fw-normal center text-capitalize si-facebook si-colored mx-0"
															>
																Login with Facebook
															</a>
															<a
																href="#"
																class="button button-rounded w-100 fw-normal center text-capitalize si-gplus si-colored m-0"
															>
																Login with Google
															</a>
														</div>
													</div>
												</div> */}

												{/* <div className="progress-group">
													Targets Plan
													<span className="float-right">
														<b>
															{!targetProgress
																? 0
																: targetProgress
																		.toFixed(2)
																		.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
														</b>
														/
														{!targetPlan
															? 0
															: targetPlan
																	.toFixed(2)
																	.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
													</span>
													<div className="progress progress-sm">
														<div
															className="progress-bar bg-danger"
															style={{
																width:
																	Math.round(showPercentageTargetData) + `%`,
															}}
														/>
													</div>
													
												</div> */}
												{/* 
												<div className="modal fade" id="modal-savings">
													<div className="modal-dialog">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title">Add Saving Plan</h5>
																<button
																	type="button"
																	className="close"
																	data-dismiss="modal"
																	aria-label="Close"
																>
																	<span aria-hidden="true">×</span>
																</button>
															</div>
															<div className="modal-body">
																<form
																	className={classes.form}
																	noValidate
																	onSubmit={handleSubmit}
																>
																	<div className="form-row">
																		<div className="col-md-12 mb-3">
																			<input
																				type="text"
																				ref={planTypeRef}
																				defaultValue="Savings"
																				hidden
																			/>
																			<input
																				type="text"
																				className="form-control border"
																				ref={planNameRef}
																				placeholder="Plan name"
																				required
																			/>
																		</div>
																	</div>
																	<div className="form-row">
																		<div className="col-md-12 mb-3">
																			<select
																				className="custom-select"
																				ref={planCategoryRef}
																			>
																				<option>Select plan category...</option>
																				<option value="starter">
																					Starter - [ 1 Month ]
																				</option>
																				<option value="classic">
																					Classic - [ 3 Month ]
																				</option>
																				<option value="prime">
																					Prime - [ 6 Month ]
																				</option>
																				<option value="ultimate">
																					Ultimate - [ 12 Month ]
																				</option>
																			</select>
																		</div>
																	</div>
																	<div className="form-row">
																		<div className="col-md-12 mb-3">
																			<input
																				type="text"
																				className="form-control border"
																				ref={planAmountRef}
																				placeholder="Amount"
																				required
																			/>
																			<div className="invalid-feedback">
																				Please enter a valid amount
																			</div>
																		</div>
																	</div>

																	<button
																		className="btn btn-danger form-control"
																		type="submit"
																	>
																		Add Plan
																	</button>
																</form>
															</div>
														</div>
													</div>
												</div> */}

												{/* <div className="modal fade" id="modal-targets">
													<div className="modal-dialog">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title">Add Target Plan</h5>
																<button
																	type="button"
																	className="close"
																	data-dismiss="modal"
																	aria-label="Close"
																>
																	<span aria-hidden="true">×</span>
																</button>
															</div>
															<div className="modal-body">
																<form
																	className={classes.form}
																	noValidate
																	onSubmit={handleSubmit}
																>
																	<div className="form-row">
																		<div className="col-md-12 mb-3">
																			<input
																				type="text"
																				ref={planTypeRef1}
																				defaultValue="Targets"
																				hidden
																			/>
																			<input
																				type="text"
																				className="form-control border"
																				ref={planNameRef1}
																				placeholder="What are you planning for?"
																				required
																			/>
																		</div>
																	</div>

																	<div className="form-row">
																		<div className="col-md-12 mb-3">
																			<input
																				type="text"
																				className="form-control border"
																				ref={planAmountRef1}
																				placeholder="Amount"
																				required
																			/>
																			<div className="invalid-feedback">
																				Please enter a valid amount
																			</div>
																		</div>
																	</div>

																	<button
																		className="btn btn-success form-control"
																		type="submit"
																	>
																		Add Plan
																	</button>
																</form>
															</div>
														</div>
													</div>
												</div> */}

												<div className="modal fade" id="modal-transactions">
													<div className="modal-dialog">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title">Add Target Plan</h5>
																<button
																	type="button"
																	className="close"
																	data-dismiss="modal"
																	aria-label="Close"
																>
																	<span aria-hidden="true">×</span>
																</button>
															</div>
															<div className="modal-body">
																<table className={classes.table}>
																	<thead>
																		<tr>
																			<th width="15%">Date/Time</th>
																			<th>Transaction ID</th>
																			<th>Transaction Type</th>
																			<th>Transaction Amount</th>
																			<th>Transaction Naration</th>
																			<th>Status</th>
																		</tr>
																	</thead>
																	<tbody>
																		{!showTableData ? <tr></tr> : showTableData}
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>

												{/* <div className="modal fade" id="modal-transactions1">
													<div className="modal-dialog">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title">Transactions</h5>
																<button
																	type="button"
																	className="close"
																	data-dismiss="modal"
																	aria-label="Close"
																>
																	<span aria-hidden="true">×</span>
																</button>
															</div>
															<div className="modal-body">
																<div className="table-responsive">
																	<table className={classes.table}>
																		<thead>
																			<tr>
																				<th width="15%">Date/Time</th>
																				<th>Transaction ID</th>
																				<th>Transaction Type</th>
																				<th>Transaction Amount</th>
																				<th>Transaction Naration</th>
																				<th>Status</th>
																			</tr>
																		</thead>
																		<tbody>
																			{!showTableData ? (
																				<tr></tr>
																			) : (
																				showTableData
																			)}
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													</div>
												</div> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}

export default DashboardPage;
