import React, { useEffect, useState } from 'react';
// import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';
import classes from '../../components/layouts/Dashboard.module.css';

// import { useTable } from 'react-table';
// import TableFooter from '../TableFooter/TableFooter';
import Table from '../Table';

const Transactions = () => {
	const [data, setData] = useState([]);
	const [load, setLoad] = useState(true);
	let user_wallet_id = sessionStorage.getItem('user_wallet_id');

	useEffect(() => {
		const data = {
			user_wallet_id: user_wallet_id,
		};

		axios
			.post(`api/fetch_all_transactions_data`, data)
			.then((res) => {
				setData(res.data.wallet_details);
				setLoad(false);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [user_wallet_id]);

	if (load) {
		return (
			<div>
				<p className={classes.loading}></p>
				<p className={classes.loadingText}>Please wait...</p>
			</div>
		);
	} else {
		return <Table data={data} rowsPerPage={10} />;
	}
};

export default Transactions;
