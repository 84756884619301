import { Link, useHistory } from 'react-router-dom';
// import { Container, Button, Form, Row, Col } from 'react-bootstrap';
import classes from '../../components/layouts/Contribute.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useRef } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

function AddPlanPage() {
	// const [disable, setDisable] = React.useState(false);
	let usertoken = sessionStorage.getItem('token');
	let user_wallet_id = sessionStorage.getItem('user_wallet_id');

	const planTypeRef = useRef();
	const planTypeRef1 = useRef();
	const planNameRef = useRef();
	const planNameRef1 = useRef();
	const planCategoryRef = useRef();
	const planAmountRef = useRef();
	const planAmountRef1 = useRef();

	// const [check, setCheck] = useState(true);

	const history = useHistory();
	if (!usertoken) {
		history.push('/');
	}

	function clear() {
		console.log(planNameRef.current.value);
		planNameRef.current.value = '';
		// planNameRef1.current.value = '';
		planAmountRef.current.value = '';
		// planAmountRef1.current.value = '';
		planCategoryRef.current.value = 'Select plan category...';
	}

	var getDaysInMonth = function (month, year) {
		return new Date(year, month, 0).getDate();
	};

	const d = new Date();
	let month = d.getMonth() + 1;
	const currentYear = new Date().getFullYear();

	async function handleSubmit(e) {
		e.preventDefault();

		var plan_type = planTypeRef.current.value;
		var plan_name = planNameRef.current.value;
		var plan_category = planCategoryRef.current.value;
		var plan_amount = planAmountRef.current.value;
		var plan_start = new Date().toLocaleDateString('en-GB');
		var plan_end = new Date();
		var plan_days;
		var plan_daily;

		if (!plan_name) {
			plan_type = planTypeRef1.current.value;
			plan_name = planNameRef1.current.value;
		}

		if (!plan_amount) {
			plan_amount = planAmountRef1.current.value;
		}

		if (plan_category === 'starter') {
			plan_end.setMonth(plan_end.getMonth() + 1);
			plan_days = getDaysInMonth(month, currentYear);
			plan_daily = plan_amount / plan_days;
		} else if (plan_category === 'classic') {
			plan_end.setMonth(plan_end.getMonth() + 3);
			plan_days =
				getDaysInMonth(month, currentYear) +
				getDaysInMonth(month + 1, currentYear) +
				getDaysInMonth(month + 2, currentYear);
			plan_daily = plan_amount / plan_days;
		} else if (plan_category === 'prime') {
			plan_end.setMonth(plan_end.getMonth() + 6);
			plan_days =
				getDaysInMonth(month, currentYear) +
				getDaysInMonth(month + 1, currentYear) +
				getDaysInMonth(month + 2, currentYear) +
				getDaysInMonth(month + 3, currentYear) +
				getDaysInMonth(month + 4, currentYear) +
				getDaysInMonth(month + 5, currentYear);
			plan_daily = plan_amount / plan_days;
		} else {
			plan_end.setMonth(plan_end.getMonth() + 12);
			plan_days =
				getDaysInMonth(month, currentYear) +
				getDaysInMonth(month + 1, currentYear) +
				getDaysInMonth(month + 2, currentYear) +
				getDaysInMonth(month + 3, currentYear) +
				getDaysInMonth(month + 4, currentYear) +
				getDaysInMonth(month + 5, currentYear) +
				getDaysInMonth(month + 6, currentYear) +
				getDaysInMonth(month + 7, currentYear) +
				getDaysInMonth(month + 8, currentYear) +
				getDaysInMonth(month + 9, currentYear) +
				getDaysInMonth(month + 10, currentYear) +
				getDaysInMonth(month + 11, currentYear);
			plan_daily = plan_amount / plan_days;
		}
		plan_end = plan_end.toLocaleDateString('en-GB');
		if (plan_type === 'Targets') {
			plan_category = plan_end = null;
		}

		try {
			let plan = {
				plan_type,
				plan_name,
				plan_category,
				plan_amount,
				user_wallet_id,
				plan_start,
				plan_end,
				plan_days,
				plan_daily,
			};

			axios
				.post(`api/register_plan`, plan)
				.then((res) => {
					// setAvailableBalance(res.data);
					// sessionStorage.setItem('available_balance', res.data);
					console.log(res.data);

					if (res.data.account_no) {
						Swal.fire({
							icon: 'success',
							title: 'Plan',
							text: 'Added Successfully!',
						});
					} else {
						Swal.fire({
							icon: 'error',
							title: 'Plan',
							text: 'Please try again!',
						});
					}
					clear();
				})
				.catch((err) => {
					console.log(err);
				});

			// let result = await fetch(
			// 	'https://savebox.cyrusnet4d.com/public/api/register_plan',
			// 	{
			// 		method: 'POST',
			// 		body: JSON.stringify(plan),
			// 		headers: {
			// 			'Content-Type': 'application/json',
			// 			Accept: 'application/json',
			// 		},
			// 	},
			// );

			// setCheck(true);
			// result = await result.json();
		} catch {}
	}

	return (
		<div className={classes.contribute}>
			<div className="container">
				<div className="content-header">
					<div className="container-fluid">
						<div className="row mb-2">
							<div className="col-sm-6">
								<h3 className="m-0">Add Plan</h3>
							</div>
							<div className="col-sm-6">
								<ol className="breadcrumb float-sm-right">
									<li className="breadcrumb-item">
										<Link to="/dashboard">Home</Link>
									</li>
									<li className="breadcrumb-item active">Add Plan</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<section className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12 col-sm-9 col-md-4">
								<form
									className={classes.form}
									noValidate
									onSubmit={handleSubmit}
								>
									<div className="form-row">
										<div className="col-md-12 mb-3">
											<input
												type="text"
												ref={planTypeRef}
												defaultValue="Savings"
												hidden
											/>
											<input
												type="text"
												className="form-control border"
												ref={planNameRef}
												placeholder="Plan name"
												required
											/>
										</div>
									</div>
									<div className="form-row">
										<div className="col-md-12 mb-3">
											<select className="custom-select" ref={planCategoryRef}>
												<option>Select plan category...</option>
												<option value="starter">Starter - [ 1 Month ]</option>
												<option value="classic">Classic - [ 3 Month ]</option>
												<option value="prime">Prime - [ 6 Month ]</option>
												<option value="ultimate">
													Ultimate - [ 12 Month ]
												</option>
											</select>
										</div>
									</div>
									<div className="form-row">
										<div className="col-md-12 mb-3">
											<input
												type="text"
												className="form-control border"
												ref={planAmountRef}
												placeholder="Amount"
												required
											/>
											<div className="invalid-feedback">
												Please enter a valid amount
											</div>
										</div>
									</div>

									<button className="btn btn-danger form-control" type="submit">
										Add Plan
									</button>
								</form>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}

export default AddPlanPage;
