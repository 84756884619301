import { useHistory } from 'react-router-dom';
// import { Container, Button, Form, Row, Col } from 'react-bootstrap';
import classes from '../../components/layouts/Contribute.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useRef } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

function MakeWithdrawalsPage() {
	const [disable, setDisable] = React.useState(false);
	let usertoken = sessionStorage.getItem('token');
	const withdrawAmountRef = useRef();
	let wallet_id = window.sessionStorage.getItem('user_wallet_id');

	const history = useHistory();
	if (!usertoken) {
		history.push('/');
	}

	function clear() {
		withdrawAmountRef.current.value = '';
		//setBeneficiary('');
	}

	const withdrawSubmit = (e) => {
		e.preventDefault();

		var withdrawAmount = withdrawAmountRef.current.value;
		var userBalance = sessionStorage.getItem('available_balance');
		withdrawAmount = Math.round(withdrawAmount);
		userBalance = Math.round(userBalance);
		var withdrawCheck = withdrawAmountRef.current.value;
		console.log(withdrawCheck.length);
		if (withdrawCheck.length > 3) {
			if (userBalance >= withdrawAmount) {
				setDisable(true);
				const data = {
					wallet_id: wallet_id,
					withdrawAmount: withdrawAmount,
				};

				axios.get('/sanctum/csrf-cookie').then((response) => {
					axios.post(`api/withdraw`, data).then((res) => {
						//console.warn(res.data);
						if (res.data.status === 200) {
							//console.log(res.data.new_sender_balance[0].acct_balance);
							sessionStorage.setItem(
								'available_balance',
								res.data.new_available_balance[0].available_bal,
							);
							clear();
							setDisable(false);
							Swal.fire({
								icon: 'success',
								title: 'Withdrawal Request',
								text: 'Successful!',
							});
						} else {
							Swal.fire({
								icon: 'error',
								title: 'Withdrawal Request',
								text: 'Failed!',
							});
							setDisable(false);
						}
					});
				});
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Withdrawal',
					text:
						'Failed! Insufficient Wallet Balance: ' +
						new Intl.NumberFormat('ja-JP', {
							style: 'currency',
							currency: 'NGN',
						}).format(userBalance),
				});
			}
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Withdrawal Request',
				text: 'Please enter a valid amount!',
			});
			setDisable(false);
		}
	};

	return (
		<div className={classes.withdraw}>
			<div className="container">
				<div className="content-header">
					<div className="container-fluid">
						<div className="row mb-2">
							<div className="col-sm-6">
								<h3 className="m-0">Withdraw</h3>
							</div>
							<div className="col-sm-6">
								<ol className="breadcrumb float-sm-right">
									<li className="breadcrumb-item">
										<a href="/dashboard">Home</a>
									</li>
									<li className="breadcrumb-item active">Withdraw</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<section className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12 col-sm-6 col-md-10">
								<form
									className={classes.form}
									noValidate
									onSubmit={withdrawSubmit}
								>
									<div className="form-row">
										<div className="col-md-4 mb-3">
											<input
												type="text"
												className="form-control border"
												id="validationCustom03"
												placeholder="Amount"
												ref={withdrawAmountRef}
												required
											/>
											<div className="invalid-feedback">
												Please enter a valid amount
											</div>
										</div>
									</div>
									<div className="row">
										<div className="p-3">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input border border-secondary"
													type="radio"
													name="inlineRadioOptions"
													id="inlineRadio1"
													value="option1"
													checked
												/>
												<label className="form-check-label" for="inlineRadio1">
													Bank Account
												</label>
											</div>
											<div className="form-check form-check-inline">
												<input
													className="form-check-input border border-secondary"
													type="radio"
													name="inlineRadioOptions"
													id="inlineRadio2"
													value="option2"
												/>
												<label className="form-check-label" for="inlineRadio2">
													Cash [Front Desk]
												</label>
											</div>
										</div>
									</div>

									<button
										className={classes.withdrawbtn}
										type="submit"
										disabled={disable}
										// onClick={() => setDisable(true)}
									>
										Withdraw
									</button>
								</form>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}

export default MakeWithdrawalsPage;
