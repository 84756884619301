import { useHistory, Link } from 'react-router-dom';
import classes from '../../components/layouts/Contribute.module.css';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useRef, useState } from 'react';
import Swal from 'sweetalert2';

function MakeContributionPage() {
	const planTypeRef = useRef();
	const amountRef = useRef();
	const dailyRef = useRef();
	let usertoken = sessionStorage.getItem('token');
	let user_wallet_id = sessionStorage.getItem('user_wallet_id');
	const [list, setList] = useState('');
	const [planAmount, setPlanAmount] = useState();
	const [planName, setPlanName] = useState();
	const [planID, setPlanID] = useState();
	const [planType, setPlanType] = useState();
	// const [check, setCheck] = useState(true);
	const history = useHistory();

	if (!usertoken) {
		history.push('/');
	}

	var listItems = [];
	if (list) {
		listItems = list.map((items) => (
			<option
				key={items.id}
				value={
					items.id +
					'-' +
					items.plan_name +
					'-' +
					Number(items.plan_daily).toFixed(2)
				}
			>
				{items.plan_name}
			</option>
		));
	}

	const getamount = () => {
		var amount = amountRef.current.value;
		// console.log(amount);
		var splitter = amount.split('-');
		//var plan_id = splitter[0];
		var dailyAmount = splitter[2];
		setPlanName(splitter[1]);
		setPlanID(splitter[0]);
		setPlanAmount(dailyAmount);

		//setPlanAmount(dailyRef.current.value);
		// console.log(planTypeRef.current.value);

		// setPlanAmount(dailyAmount);

		//console.log(planAmount);
		//setCheck(true);
	};

	const fetch_contributions_data = () => {
		const data = {
			user_wallet_id: user_wallet_id,
			plan_type: planTypeRef.current.value,
		};

		axios.get('/sanctum/csrf-cookie').then((response) => {
			axios.post(`api/contribution_data`, data).then((res) => {
				//console.log(res.data);
				setList(res.data);
				setPlanType(planTypeRef.current.value);
				if (!res.data.length) {
					Swal.fire({
						icon: 'error',
						title: 'Plan',
						text: 'Please add a plan!',
					});
					setPlanAmount('');
				}
				setPlanAmount('');
			});
		});
	};

	const contributeSubmit = (e) => {
		e.preventDefault();
		if (planTypeRef.current.value === 'savings') {
			dailyRef.current.value = parseFloat(
				dailyRef.current.value.replace(/,/g, ''),
			);
		}
		// console.log(Number(dailyRef.current.value));

		const data = {
			wallet_id: user_wallet_id,
			plan_name: planName,
			plan_id: planID,
			plan_amount: Number(dailyRef.current.value),
			plan_category: planTypeRef.current.value,
		};

		axios.get('/sanctum/csrf-cookie').then((response) => {
			axios.post(`api/contribute`, data).then((res) => {
				//console.warn(res.data);
				if (res.data.status === 200) {
					// localStorage.setItem('auth-token', res.data.token);
					// localStorage.setItem('auth-email', res.data.email);
					// if (res.data.message === 'successful') {
					Swal.fire({
						icon: 'success',
						title: 'Contribution',
						text: 'Successful!',
					});

					planTypeRef.current.value = 'Select category...';
					dailyRef.current.value = '';
					setList();
					setPlanAmount('');
					// } else {
					// 	Swal.fire({
					// 		icon: 'success',
					// 		title: 'Contribution Plan',
					// 		text: res.data.message,
					// 	});

					// 	planTypeRef.current.value = 'Select category...';
					// 	dailyRef.current.value = '';
					// 	setList();
					// 	setPlanAmount('');
					// }
				} else {
					Swal.fire({
						icon: 'error',
						title: 'Payment',
						text: 'Failed!',
					});
				}
			});
		});
	};

	return (
		<div className={classes.contribute}>
			<div className="container">
				<div className="content-header">
					<div className="container-fluid">
						<div className="row mb-2">
							<div className="col-sm-6">
								<h3 className="m-0">Contribute</h3>
							</div>
							<div className="col-sm-6">
								<ol className="breadcrumb float-sm-right">
									<li className="breadcrumb-item">
										<Link to="/dashboard">Home</Link>
									</li>
									<li className="breadcrumb-item active">Contribute</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<section className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12 col-sm-9 col-md-9">
								<form
									className={classes.form}
									noValidate
									onSubmit={contributeSubmit}
								>
									<div className="form-row">
										<div className="col-md-5 mb-3">
											<select
												className="custom-select"
												ref={planTypeRef}
												onChange={fetch_contributions_data}
											>
												<option>Select category...</option>
												<option value="savings">Savings</option>
												<option value="targets">Targets</option>
											</select>
										</div>
									</div>
									<div className="form-row">
										<div className="col-md-5 mb-3">
											<select
												className="custom-select"
												ref={amountRef}
												onChange={getamount}
											>
												<option>Select plan...</option>
												{listItems}
											</select>
										</div>
									</div>
									<div className="form-row">
										<div className="col-md-5 mb-3">
											{/* {console.log(planType)} */}
											{planType !== 'targets' ? (
												<input
													type="text"
													defaultValue={
														!planAmount
															? ''
															: Number(planAmount)
																	.toFixed(2)
																	.replace(/\d(?=(\d{3})+\.)/g, '$&,')
													}
													className="form-control"
													ref={dailyRef}
													placeholder="Amount"
													disabled
												/>
											) : (
												// (console.log(planAmount),
												<input
													type="text"
													defaultValue={
														!planAmount
															? ''
															: Number(planAmount)
																	.toFixed(2)
																	.replace(/\d(?=(\d{3})+\.)/g, '$&,')
													}
													className="form-control border"
													ref={dailyRef}
													placeholder="Amount"
												/>
											)}

											<div className="invalid-feedback">
												Please enter a valid amount
											</div>
										</div>
									</div>

									<button className={classes.contributebtn} type="submit">
										Contribute Now
									</button>
								</form>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}

export default MakeContributionPage;
